import { Label, Radio, RadioGroup } from '@headlessui/react'
import { classNames } from '@/util/misc'
import Stripe from 'stripe'
import { ReactNode } from 'react'

export function PricingIntervalPicker(props: {
  intervals: Stripe.Price.Recurring.Interval[]
  label?: string
  value: Stripe.Price.Recurring.Interval
  onChange: (interval: Stripe.Price.Recurring.Interval) => void
}) {
  if (!props.intervals.length) {
    return null
  }

  const labels: Record<Stripe.Price.Recurring.Interval, ReactNode> = {
    day: 'Daily',
    month: 'Monthly',
    week: 'Weekly',
    year: (
      <span>
        Annual <span className="text-[11px]">(save 15%)</span>
      </span>
    ),
  }

  return (
    <RadioGroup
      value={props.value}
      onChange={props.onChange}
      className={classNames(
        'grid gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200',
        `grid-cols-${props.intervals.length}`,
      )}
    >
      {props.label && <Label className="sr-only">{props.label}</Label>}

      {props.intervals.map((interval) => (
        <Radio
          key={interval}
          value={interval}
          className={({ checked }) =>
            classNames(
              checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
              'cursor-pointer rounded-full px-2.5 py-1',
            )
          }
        >
          <span>{labels[interval]}</span>
        </Radio>
      ))}
    </RadioGroup>
  )
}
