import { useState } from 'react'
import Link from 'next/link'
import { pricingPrices } from '@/shared/pricing-price'
import { PricingIntervalPicker } from '@/components/PricingIntervalPicker'
import {
  PricingCard,
  PricingCardSize,
  PricingCardTheme,
} from '@/components/PricingCard'

const intervals = [...new Set(pricingPrices.map((price) => price.interval))]

export function Pricing() {
  const [interval, setInterval] = useState(intervals[0])

  if (interval === undefined) {
    return null
  }

  const prices = pricingPrices.filter((price) => price.interval === interval)

  return (
    <div className="bg-white py-24 sm:py-32" id="pricing">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Choose a plan that fits your needs
          </h2>
        </div>
        <div className="mx-6 mt-6 flex justify-center">
          <div className="relative cursor-pointer rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-indigo-600">
            <Link href="/education" aria-hidden="true">
              Free for education{' '}
              <span
                className="font-semibold text-indigo-600 hover:text-indigo-500"
                style={{ whiteSpace: 'pre' }}
              >
                Learn more →
              </span>
            </Link>
          </div>
        </div>

        {intervals.length > 1 && (
          <div className="mt-6 flex justify-center">
            <PricingIntervalPicker
              intervals={intervals}
              value={interval}
              onChange={setInterval}
            />
          </div>
        )}

        <div className="isolate mx-auto mt-10 flex max-w-md flex-col gap-8 lg:mx-0 lg:max-w-none lg:flex-row">
          {prices.map((price) => (
            <PricingCard
              className="flex-1"
              key={price.id}
              price={price}
              size={PricingCardSize.L}
              badge={price.name === 'Pro' ? 'Best value' : undefined}
              theme={
                price.name === 'Pro'
                  ? PricingCardTheme.Primary
                  : PricingCardTheme.Default
              }
              button={{
                href: `/api/signup?tier=${price.signUpEndpointTier}`,
                text: 'Subscribe',
              }}
            />
          ))}
        </div>

        <div className="mx-auto mt-8 flex max-w-md flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:max-w-none lg:flex-row lg:items-center">
          <div className="lg:min-w-0 lg:flex-1">
            <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
              Free tier
            </h3>
            <p className="mt-1 text-base leading-7 text-gray-600">
              Great for trying things out. You get 10 free generations per month
              after signing up.
            </p>
          </div>
          <Link
            href="/api/signup"
            className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Try now <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  )
}
