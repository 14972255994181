import { classNames } from '@/util/misc'
import { CheckIcon } from '@heroicons/react/20/solid'
import Link from 'next/link'
import { PricingPrice } from '@/shared/pricing-price'

export enum PricingCardTheme {
  Dark = 'dark',
  Default = 'default',
  Primary = 'primary',
}

export enum PricingCardSize {
  XL = 'xl',
  L = 'l',
}

type PricingCardProps = {
  className?: string
  size: PricingCardSize
  theme: PricingCardTheme
  badge?: string
  button: {
    text: string
    onClick?: () => void
    href?: string
  }
  price: PricingPrice
}

export function PricingCard(props: PricingCardProps) {
  return (
    <div
      key={props.price.id}
      className={classNames(
        props.className,
        {
          [PricingCardTheme.Dark]:
            'bg-gray-900 text-gray-300 ring-1 ring-gray-900',
          [PricingCardTheme.Default]: 'text-gray-600 ring-1 ring-gray-200',
          [PricingCardTheme.Primary]:
            // XL is already emphasized enough due to its size
            props.size === PricingCardSize.XL
              ? 'text-gray-600 ring-1 ring-gray-200'
              : 'text-gray-600 ring-2 ring-indigo-600',
        }[props.theme],
        'rounded-3xl p-8 xl:p-10',
      )}
    >
      <div
        className={classNames(
          'grid',
          {
            [PricingCardSize.XL]:
              "gap-6 [grid-template-areas:'name''price''description''features''button']",
            [PricingCardSize.L]:
              "gap-4 [grid-template-areas:'name''description''price''button''features']",
          }[props.size],
        )}
      >
        <div className="flex items-center justify-between gap-x-4 [grid-area:name]">
          <h3
            id={props.price.id}
            className={classNames(
              {
                [PricingCardTheme.Dark]: 'text-white',
                [PricingCardTheme.Default]:
                  props.size === PricingCardSize.XL
                    ? 'text-indigo-600'
                    : 'text-gray-900',
                [PricingCardTheme.Primary]: 'text-indigo-600',
              }[props.theme],
              'text-lg font-semibold leading-8',
            )}
          >
            {props.price.name}
          </h3>
          {props.badge && (
            <p className="rounded-full bg-indigo-100 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-800">
              {props.badge}
            </p>
          )}
        </div>

        {props.price.description && (
          <p
            className={classNames(
              '[grid-area:description]',
              {
                [PricingCardSize.XL]: 'text-base leading-7',
                [PricingCardSize.L]: 'text-sm leading-6',
              }[props.size],
            )}
          >
            {props.price.description}
          </p>
        )}

        <div className="[grid-area:price]">
          <PricingCardPrice
            price={props.price}
            size={props.size}
            theme={props.theme}
          />
        </div>

        <div className="mt-2 [grid-area:button]">
          {(() => {
            const className = classNames(
              'w-full flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
              {
                [PricingCardTheme.Dark]: classNames(
                  'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                ),
                [PricingCardTheme.Default]: classNames(
                  'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'focus-visible:outline-indigo-600',
                ),
                [PricingCardTheme.Primary]: classNames(
                  'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500',
                  'focus-visible:outline-indigo-600',
                ),
              }[props.theme],
            )

            if (props.button.href) {
              return (
                <Link
                  href={props.button.href}
                  className={className}
                  aria-describedby={props.price.id}
                >
                  {props.button.text}
                </Link>
              )
            }

            if (props.button.onClick) {
              return (
                <button
                  type="button"
                  onClick={props.button.onClick}
                  className={className}
                  aria-describedby={props.price.id}
                >
                  {props.button.text}
                </button>
              )
            }

            return props.button.text
          })()}
        </div>

        <PricingCardFeatures
          features={props.price.features}
          size={props.size}
          theme={props.theme}
          className="mt-4 [grid-area:features]"
        />
      </div>
    </div>
  )
}

function PricingCardFeatures(props: {
  className?: string
  features: PricingPrice['features']
  size: PricingCardSize
  theme: PricingCardTheme
}) {
  return (
    <ul
      className={classNames(
        props.className,
        'mt-4 space-y-3 text-sm leading-6',
      )}
      role="list"
    >
      {props.features.map((feature, index) => (
        <li key={index} className="flex gap-x-3">
          <CheckIcon
            className={classNames(
              'h-6 w-5 flex-none',
              {
                [PricingCardTheme.Dark]: 'text-gray-300',
                [PricingCardTheme.Default]: 'text-indigo-600',
                [PricingCardTheme.Primary]: 'text-indigo-600',
              }[props.theme],
            )}
            aria-hidden="true"
          />
          {(feature.name === 'Image Editing' && (
            <a
              key={index}
              href="https://blog.newarc.ai/editing-tools-inpaint-regenerate-and-refine-for-perfect-results/"
              target="_blank"
              className="text-indigo-600 hover:underline"
              rel="noreferrer"
            >
              {feature.name}
            </a>
          )) ||
            (feature.name === 'Team management' && (
              <div>
                <span className="mr-2 whitespace-nowrap">{feature.name}</span>
                <strong className="inline-block whitespace-nowrap rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
                  Coming soon
                </strong>
              </div>
            )) ||
            feature.name}
        </li>
      ))}
    </ul>
  )
}

function PricingCardPrice(props: {
  price: PricingPrice
  size: PricingCardSize
  theme: PricingCardTheme
}) {
  const mainPrice =
    props.price.interval === 'year'
      ? {
          ...props.price,
          amount: Math.round(props.price.amount / 12),
          interval: 'month',
        }
      : props.price

  return (
    <div
      className={classNames(
        'leading-normal',
        {
          [PricingCardSize.XL]: 'text-base',
          [PricingCardSize.L]: 'text-sm',
        }[props.size],
        {
          [PricingCardTheme.Dark]: 'text-white',
          [PricingCardTheme.Default]: 'text-gray-600',
          [PricingCardTheme.Primary]: 'text-gray-600',
        }[props.theme],
      )}
    >
      <div className="flex items-baseline font-semibold">
        {mainPrice.startingFrom && <span className="mr-2">from</span>}
        <span
          className={classNames(
            'font-bold tracking-tight',
            {
              [PricingCardSize.XL]: 'text-5xl',
              [PricingCardSize.L]: 'text-4xl',
            }[props.size],
            {
              [PricingCardTheme.Dark]: 'text-white',
              [PricingCardTheme.Default]: 'text-gray-900',
              [PricingCardTheme.Primary]: 'text-gray-900',
            }[props.theme],
          )}
        >
          ${mainPrice.amount}
        </span>
        <span
          className={classNames(
            'ml-1 text-base text-gray-500',
            {
              [PricingCardSize.XL]: 'font-normal',
              [PricingCardSize.L]: 'font-semibold',
            }[props.size],
          )}
        >
          /{mainPrice.interval}
          {mainPrice.perSeat && `/user`}
        </span>
      </div>
      {props.price.interval === 'year' && (
        <div className="mt-1 text-gray-500">
          billed ${props.price.amount}
          {mainPrice.perSeat && `/user`} annually
        </div>
      )}
    </div>
  )
}
