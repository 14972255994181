import Link from 'next/link'
import { navigation } from './Navigation'

export function Footer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-4 py-16 sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map(
            (item) =>
              (item.location === 'both' || item.location === 'footer') && (
                <div key={item.name} className="px-5 py-2">
                  <a
                    href={item.href}
                    className="text-base text-gray-500 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                </div>
              ),
          )}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          <a
            href="mailto:support@newarc.ai"
            className="text-base text-gray-500 hover:text-gray-900"
          >
            support@newarc.ai
          </a>
          {/* {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))} */}
        </div>
        <p className="mt-8 text-center text-base text-gray-400">
          <Link href="/terms-of-service" className="px-3">
            Terms of service
          </Link>
          <Link href="/privacy-policy" className="px-3">
            Privacy policy
          </Link>
          <Link href="/affiliate-program-agreement" className="px-3">
            Affiliate program agreement
          </Link>
        </p>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; {new Date().getFullYear()} NewArc. All rights reserved.
        </p>
        <p className="mt-2 text-center text-base text-gray-400">
          Variable Ltd (No. 13300116). Registered address: 71-75 Shelton Street,
          London, England, WC2H 9JQ
        </p>
      </div>
    </footer>
  )
}
