import Link from 'next/link'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question:
      'Do I need to code or have any special skills to use this service (Python, Git, Colab, etc.)?',
    answer:
      'No special knowledge or skills required – you don’t even need to draw like a pro! As long as you can roughly sketch your ideas on paper or digitally, NewArc will do the rest. Alternatively, you can even use the sketches you found online (but make sure you’re not breaching third-party copyright!).',
  },
  {
    question: 'Are there tutorial videos on how to use the service?',
    answer: (
      <span>
        We've made a short course of 10 bite-size videos for you to watch.{' '}
        <Link
          href="https://www.youtube.com/playlist?list=PLUKUhhyAgRaCZBWfHJGyihdMLMBvj4i-l"
          className="font-semibold text-indigo-600 hover:text-indigo-500"
          style={{ whiteSpace: 'pre' }}
          target="_blank"
          rel="noreferrer"
        >
          Watch on YouTube →
        </Link>
      </span>
    ),
  },
  {
    question: 'Can I cancel a subscription at any time?',
    answer:
      'Yes, you can cancel your subscription at any time and the service will stop working at the end of the paid period. No penalties or anything like that.',
  },
  {
    question: "Can I buy additional credit when I've reached my monthly limit?",
    answer:
      'Yes, you can restart your current plan at any time and get new credits immediately. We currently offer three membership plans with different limits – choose the one that fits your needs best, or speak to our team if you’re not sure. If turning drawings into images is something you do a lot at work, consider opting for an unlimited plan.',
  },
  {
    question: 'Can I use the service for free?',
    answer:
      'You will get 10 monthly credits after you sign up to turn your sketches into photos completely free of charge. Once you run out of complimentary credits, you can always purchase one of our plans to continue enjoying NewArc.ai. Don’t forget that you can get 50% off your first month when you join!',
  },
  {
    question:
      'What is "Full privacy" and will other users be able to use my designs to generate their images?',
    answer:
      '"Full privacy" means that nobody can see your images. All your sketches, prompts and images are solely yours, and can only be accessed from your personal account. It\'s entirely up to you if you choose to share them publicly. We will never feature your designs on our website, social media, or any other public platforms without your explicit consent. Moreover, we don’t use your materials to fine-tune our AI model, either: this way we ensure that your ideas never reappear in other user\'s designs.',
  },
  {
    question: 'Do you have special pricing for education?',
    answer: (
      <span>
        NewArc.ai is freely available to schools, universities, and online
        courses.{' '}
        <Link
          href="/education"
          className="font-semibold text-indigo-600 hover:text-indigo-500"
          style={{ whiteSpace: 'pre' }}
        >
          See details →
        </Link>
      </span>
    ),
  },
  {
    question: 'Do you have an affiliate program?',
    answer: (
      <span>
        Of course! We love it when our clients recommend us to friends, and we
        will be happy to reward you with 20% recurrent commission.{' '}
        <Link
          href="/affiliate-program"
          className="font-semibold text-indigo-600 hover:text-indigo-500"
          style={{ whiteSpace: 'pre' }}
        >
          See details →
        </Link>
      </span>
    ),
  },
  {
    question: 'Can I use it for my client project?',
    answer:
      'NewArc.ai is perfect for taking your client work to a whole new level. Turning sketches to real images can help you present your concepts and ideas in the most persuasive, perfectly self-explanatory form. The best part? No more hours of drawing, editing and polishing every render: all your visuals will take minutes to produce.',
  },
  {
    question: 'What is your refund policy & cancellation policy?',
    answer:
      'While we’re confident that you’ll love our product, we understand that sometimes our users may realise that they need something different. That’s why we offer a 30-day free cancellation policy on all our paid plans. As long as you cancel your subscription within the 30 days since your last payment, we will provide you with a full refund – no questions asked!',
  },
  {
    question: 'How long does each render take?',
    answer:
      'Seconds. That’s how long our AI sketch to image generator will take to process your request. Usually it actually takes longer to come up with the right image description rather than generate one. You may spend some time updating your prompt until you get the desired result, but it’s still saving you hours of manual drawing work.',
  },
  {
    question: 'What file formats of sketch do you support?',
    answer:
      'Our AI engine processes sketches, illustrations, and photos in raster image formats. The most popular formats in this category are JPEG and PNG. If your images are in a vector (EPS, SVG) or a container (HEIC) format, you may have to convert it into a JPEG or a PNG image first.',
  },
  {
    question: 'Who owns the copyright to the renders?',
    answer:
      'The renders are yours to use however you like – as long as you are turning your own drawings into images, you are the only copyright holder. We kindly remind you that using third-party sketches and photos may result in a breach of copyright if it is easy to recognise someone else’s work in your renders.',
  },
  {
    question: 'Can NewArc.ai generate new ideas?',
    answer:
      'That’s exactly its primary function! Our AI has been trained on thousands and thousands of different designs – think compressing all fashion, interior, industrial and architecture degree programmes out there into one tool. Our AI knows everything about mixing and matching colour palettes, materials, textures and ornaments: whatever ideas you may have in mind while playing around with NewArc.ai, you’ll be surprised how many more variations it can offer.',
  },
]

export function Faqs() {
  return (
    <div className="bg-gray-50" id="faq">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <div className="mt-6 max-w-2xl text-base leading-7 text-gray-600">
            Haven’t found an answer to yours?{' '}
            <a
              href="mailto:support@newarc.ai"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              Reach out to our support team
            </a>{' '}
            by email – we’ll get back to you as soon as we can.
          </div>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
