import Stripe from 'stripe'
import { isDev } from '@/util/env'
import { SignUpEndpointTier } from '@/shared/sign-up-endpoint-tier'
import { ReactNode } from 'react'

export type PricingPrice = {
  id: Stripe.Price['id']
  name: Stripe.Product['name']
  description: Stripe.Product['description']
  amount: number
  interval: Stripe.Price.Recurring.Interval
  perSeat?: boolean
  startingFrom?: boolean
  features: Array<Stripe.Product.MarketingFeature | { name: ReactNode }>
  signUpEndpointTier: SignUpEndpointTier
  adjustableQuantity: boolean
}

const basic = {
  name: 'Basic',
  description: 'Great for occasional use',
  features: [
    { name: 'Up to 600 generations per month' },
    { name: 'Full privacy' },
    { name: 'No watermarks' },
  ],
  signUpEndpointTier: SignUpEndpointTier.Basic,
  adjustableQuantity: false,
}

const pro = {
  name: 'Pro',
  description: 'Ideal for individual professionals',
  features: [
    { name: 'Unlimited generations' },
    { name: 'Image Editing' },
    { name: 'Full privacy' },
    { name: 'No watermarks' },
  ],
  signUpEndpointTier: SignUpEndpointTier.Pro,
  adjustableQuantity: false,
}

export const business = {
  name: 'Business',
  description: 'Creativity without limits for teams and businesses',
  features: [
    { name: 'Unlimited generations' },
    { name: 'Image Editing' },
    { name: 'Custom onboarding' },
    { name: 'Priority support' },
    { name: 'Centralized billing' },
    { name: 'Full privacy' },
    { name: 'No watermarks' },
    { name: 'Team management' },
  ],
  signUpEndpointTier: SignUpEndpointTier.Business,
  perSeat: true,
  adjustableQuantity: true,
}

export const businessMonthlyPrice: PricingPrice = {
  ...business,
  id: isDev
    ? 'price_1PtAv8Jd03WjWYq3eLGxAj2l'
    : 'price_1Pt9bpJd03WjWYq3bgR8nB2B',
  interval: 'month',
  amount: 99,
}

export const businessAnnualPrice: PricingPrice = {
  ...business,
  id: isDev
    ? 'price_1PtAuMJd03WjWYq3iLdQa5O2'
    : 'price_1Pt9apJd03WjWYq38vq09KH5',
  interval: 'year',
  amount: 990,
}

export const pricingPrices: PricingPrice[] = [
  {
    ...basic,
    id: isDev
      ? 'price_1PuHMWJd03WjWYq3mUI9gYl0'
      : 'price_1PuyRDJd03WjWYq3UdfJiGiq',
    interval: 'month',
    amount: 19,
  },
  {
    ...pro,
    id: isDev
      ? 'price_1PtAyMJd03WjWYq3utpM8Y5o'
      : 'price_1PkSi9Jd03WjWYq3DPO2jRtm',
    interval: 'year',
    amount: 390,
  },

  businessAnnualPrice,

  {
    ...pro,
    id: isDev
      ? 'price_1PkLkeJd03WjWYq3areOHaVZ'
      : 'price_1PkShJJd03WjWYq3BbgoaV1L',
    interval: 'month',
    amount: 39,
  },
  businessMonthlyPrice,
]
