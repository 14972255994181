import RyanBelchamberImage from '@/images/testimonials/ryan-belchamber.jpg'
import CarlosRibeiroImage from '@/images/testimonials/carlos-ribeiro.jpg'
import IlincaLunguImage from '@/images/testimonials/ilinca-lungu.jpeg'
import KarinaDefelippeImage from '@/images/testimonials/karina-defelippe.jpeg'
import HenocMonteImage from '@/images/testimonials/henoc-monte.jpeg'
import CelinaJdesignsImage from '@/images/testimonials/celina-j-designs.jpeg'
import Image from 'next/image'

const testimonials = [
  {
    body: "This software is incredible! I've used it to present initial sketches to my manager - instead of trying to explain my 3D sketches, I can now render them and bring them to life in seconds. It has saved me a lot of time trying to create a render on my iPad! It's so powerful!",
    author: {
      name: 'Ryan Belchamber',
      title: 'Industrial Designer',
      imageUrl: RyanBelchamberImage,
    },
  },
  {
    body: "I'm very impressed with the results you can get. I've been using other tools for some time, and I can say that NewArc.ai gives much better results. I've already started using it in my design process.",
    author: {
      name: 'Henoc Monte',
      title: 'Senior Footwear Designer, Adidas',
      imageUrl: HenocMonteImage,
    },
  },
  {
    body: 'Using a sketch, I was able to quickly visualize the product in a respectable quality and with realistic materials. What impressed me most was that with just a few lines, the product was recreated better than I had imagined!',
    author: {
      name: 'Carlos Ribeiro',
      title: 'Footwear Designer',
      imageUrl: CarlosRibeiroImage,
    },
  },

  {
    body: "This new AI tool alows you to instantly visualize your 3D Garments from a flat illustration. It also alows you to visualize different materials and colors. It's like having a digital genie at your disposal.",
    author: {
      name: 'Karina Defelippe',
      title: 'Fashion designer',
      imageUrl: KarinaDefelippeImage,
    },
  },

  {
    body: 'Cool AI platform alert! Visualising sketches and doodles has become a lot easier with this enhancer tool, I had a lot of fun exploring NewArc.ai.',
    author: {
      name: 'Ilinca Lungu',
      title: '3D designer, Adidas',
      imageUrl: IlincaLunguImage,
    },
  },
  {
    body: 'This service allows me to instantly see the garment design in various fabrics & colours which is really insightful to me and my clients, giving them a quick mock up of their ideas. Cool, right? 🤯',
    author: {
      name: 'CelinaJdesigns',
      title: 'Creative design studio',
      imageUrl: CelinaJdesignsImage,
    },
  },
  // More testimonials...
]

export function Testimonials() {
  return (
    <div className="bg-gray-50 py-24 sm:py-32" id="testimonials">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Hear from our users
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Discover how we help creative minds to achieve more.
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author.title}
                className="pt-8 sm:inline-block sm:w-full sm:px-4"
              >
                <figure className="rounded-2xl bg-white p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <Image
                      className="h-10 w-10 rounded-full bg-gray-50"
                      src={testimonial.author.imageUrl}
                      alt=""
                    />
                    <div>
                      <div className="font-semibold text-gray-900">
                        {testimonial.author.name}
                      </div>
                      <div className="text-gray-600">
                        {testimonial.author.title}
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
