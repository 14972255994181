import Image from 'next/image'
import AdidasImage from '@/images/logo_cloud/adidas.png'
import UnderArmourImage from '@/images/logo_cloud/under-armour.png'
import HandmImage from '@/images/logo_cloud/handm.png'
import PumaImage from '@/images/logo_cloud/puma.png'
import SalomonImage from '@/images/logo_cloud/salomon.png'
import ReebokImage from '@/images/logo_cloud/reebok.png'
import DecathlonImage from '@/images/logo_cloud/decathlon.png'
import OnImage from '@/images/logo_cloud/on.png'

const logos = [
  {
    image: AdidasImage,
    alt: 'Adidas',
  },
  {
    image: UnderArmourImage,
    alt: 'Under Armour',
  },
  {
    image: PumaImage,
    alt: 'Puma',
  },
  {
    image: SalomonImage,
    alt: 'Salomon',
  },
  {
    image: HandmImage,
    alt: 'H&M',
  },
  {
    image: ReebokImage,
    alt: 'Reebok',
  },
  {
    image: DecathlonImage,
    alt: 'Decathlon',
  },
  {
    image: OnImage,
    alt: 'On',
  },
]

export function LogoCloud() {
  return (
    <div className="bg-white pb-24 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2 className="text-lg font-semibold leading-8 text-gray-900">
            Empowering creatives across the world – from individual freelancers
            to global brand teams
          </h2>
          <div className="mx-auto mt-10 grid grid-cols-4 items-start gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
            {logos.map((logoItem, idx) => (
              <Image
                className="col-span-2 max-h-12 w-full object-contain object-left lg:col-span-1"
                key={idx}
                src={logoItem.image}
                alt={logoItem.alt}
                width={158}
                height={48}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
