import step1 from '@/images/how_it_works/step1.jpg'
import step2 from '@/images/how_it_works/step2.jpg'
import step3 from '@/images/how_it_works/step3.jpg'
// import { PlayCircleIcon } from '@heroicons/react/20/solid'
import Image from 'next/image'
import Link from 'next/link'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const posts = [
  {
    id: 1,
    title: '1. Define your composition with an initial image',
    description:
      'Upload any photo, illustration, or a sketch to set the composition. This way you’ll have more control of the output.',
    imageUrl: step1,
  },
  {
    id: 2,
    title: '2. Describe your desired result',
    description:
      'Guide the creative process by providing a description of what you want. our prompt will set the aesthetic, materials, and colors of the final output.',
    imageUrl: step2,
  },
  {
    id: 3,
    title: '3. Generate design options',
    description:
      'Get the AI to generate a variety of unique design options based on your input. Tweak the prompt as needed to create even more concepts. Repeat until you have the desired result.',
    imageUrl: step3,
  },
]

export function HowItWorks() {
  const [open, setOpen] = useState(false)

  return (
    <div className="bg-gray-50 py-24 sm:py-32" id="how-it-works">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative -mt-10 w-screen max-w-2xl transform overflow-hidden text-left shadow-xl transition-all">
                  <div
                    className="mb-3 flex cursor-pointer justify-end"
                    onClick={() => setOpen(false)}
                  >
                    <XMarkIcon className="w-6 rounded-full bg-white text-gray-400 sm:w-8" />
                  </div>
                  <div className="aspect-h-3 aspect-w-5">
                    <iframe
                      src="https://www.youtube.com/embed/eMBpTw-kQyE?si=jHp860sr1GUVxI0k&autoplay=1"
                      title="YouTube video player"
                      allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                      allowFullScreen
                      className="rounded"
                    ></iframe>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Visualise your ideas in three easy steps
          </h2>
        </div>
        <div
          onClick={() => {
            setOpen(true)
          }}
          className="mx-6 mt-6 flex justify-center"
        >
          <div className="relative cursor-pointer rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-indigo-600">
            One-minute demo video.{' '}
            <span className="font-semibold text-indigo-600" aria-hidden="true">
              Watch &rarr;
            </span>
          </div>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="flex flex-col items-start justify-start"
            >
              <div className="relative w-full">
                <Image
                  className="aspect-[3/2] w-full rounded-2xl bg-gray-100 object-cover"
                  src={post.imageUrl}
                  alt={post.title}
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="group relative">
                  <h3 className="mt-6 text-lg font-semibold leading-6 text-gray-900">
                    <span className="absolute inset-0" />
                    {post.title}
                  </h3>
                  <p className="mt-2 text-sm leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            href="/api/signup"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Try now for free
          </Link>
        </div>
      </div>
    </div>
  )
}
