import {
  ArrowTrendingUpIcon,
  SparklesIcon,
  PaintBrushIcon,
  StarIcon,
  PhotoIcon,
  CheckBadgeIcon,
  CubeIcon,
  LightBulbIcon,
  RectangleGroupIcon,
} from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Generate unlimited design ideas from scratch.',
    description:
      'All you need is a rough sketch of what you want – the AI will do the rest. Give it some hints to get exactly what you want.',
    icon: LightBulbIcon,
  },
  {
    name: 'Visualise your concepts.',
    description:
      'No more hefty concept descriptions. Simply show your clients an image of their future product – and watch them fall in love with it.',
    icon: PhotoIcon,
  },
  {
    name: 'Generate images in the style you want.',
    description:
      'Create images that match your needs by choosing a specific style. Fine-tune the output by experimenting with the text prompts.',
    icon: PaintBrushIcon,
  },
  {
    name: 'Speed up your workflow.',
    description:
      'Save hours of your time by delegating tedious work to AI. No more manual drawing or photoshopping: see your ideas coming to life in seconds.',
    icon: ArrowTrendingUpIcon,
  },
  {
    name: 'Get predictable results.',
    description:
      'Use your initial sketch to define the composition ensuring that every generated image meets your expectations.',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Build 3D renderings with ease.',
    description:
      'Use our sketch to render feature to showcase your projects in realistic 3D renderings – perfect for architectural projects, landscaping and interior design.',
    icon: CubeIcon,
  },
  {
    name: 'No technical knowledge required.',
    description:
      "You don't need to be a technical expert or AI specialist. Not even a graphic artist, really. Enjoy our intuitive interface designed for everyone to use with ease.",
    icon: SparklesIcon,
  },
  {
    name: '100% unique designs.',
    description:
      'Get myriads of exclusive design ideas that no one has ever seen. Say goodbye to stock images – turn your sketches into original digital art of your own.',
    icon: StarIcon,
  },
  {
    name: 'Full copyright ownership.',
    description:
      'All images generated by our service belong exclusively to you. You can use them without any legal restrictions.',
    icon: CheckBadgeIcon,
  },
]

export function Features() {
  return (
    <div className="bg-gray-50 py-24 sm:py-32" id="features">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything you wanted from AI
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Focus on your ideas – we will take care of the rest.
          </p>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-20 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <feature.icon
                  className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
